<!--
 * @Author: dongjia
 * @Date: 2021-09-17 18:01:37
 * @LastEditTime: 2021-09-22 10:48:49
 * @LastEditors: aleaner
 * @Description: 项目初始页面
 * @FilePath: \saas-admin-vue\src\modules\home\views\Home.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div style="min-height: 50vh" v-loading="true"></div>
</template>

<script>
export default {
  computed: {
    sData() {
      return this.$store.getters.sidebarData
    },
  },
  watch: {
    'sData.length'() {
      this.handleSData()
    },
  },
  created() {
    this.handleSData()
  },
  methods: {
    handleSData() {
      // console.log(this.$store.getters.sidebarData)
      if (this.$store.getters.sidebarData.length) {
        // if (this.$store.getters.sidebarData[0].url) {
        //   this.$router.push({ name: this.$store.getters.sidebarData[0].url });
        // } else if (this.$store.getters.sidebarData[0].children?.length) {
        //   this.$router.push({ name: this.$store.getters.sidebarData[0].children[0].url });
        // }
        this.handleChildUrl(this.sData)
      }
    },
    handleChildUrl(children) {
      if (children?.length && children[0].url) {
        // console.log('Home 自己决定跳哪里', children[0].url)
        // if (children[0].url === 'Overview') {
        //   // 不知道为啥不过滤会报错，可能注册的路由中，这个排在第一个吧
        // 破案了，在 Admin.vue 重复判断了，注释掉那边的代码
        //   return
        // }
        this.$router.push({
          name: children[0].url,
        })
      } else if (!children.length) {
      } else {
        // 给没有 Overview 这个菜单节点的后台用户用的
        this.handleChildUrl(children[0].children)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
